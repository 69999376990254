* {
    margin: 0;
    padding: 0;
}

a { color: initial; text-decoration: none; }
a:visited { color: initial; }

@import './me';

.page-bg {
    background-color: #e5e5f7;
    opacity: 0.5;
    background-image: radial-gradient(#444cf7 1.1px, #e5e5f7 1.1px);
    background-size: 22px 22px;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.page-container {
    font-family: 'Roboto';
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 1;
    display: grid;
    place-items: center;

    .content-container {
        width: 730px;

        header {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            padding: 40px;
            border-radius: 10px;
            background: #ffffff;
            // box-shadow:  20px 20px 60px #d9d9d9,
            //             -20px -20px 60px #ffffff;
            box-shadow: 0px 0px 30px #d9d9d9, 0px 1px 3px #ccc;;

            .frame {
                font-size: 10px;
                border-radius: 10px;
                padding: 0 10px;
            }
    
            .header-text-container {
                margin-left: 30px;
                border: 5px solid black;
                padding: 30px 40px;
                position: relative;
                text-align: center;

                svg {
                    position: absolute;
                    top: -13px;
                    height: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: white;
                }
                            
                h1 {
                    font-family: 'Oswald';
                    font-size: 60px;
                    font-weight: 500;
                }
            }
        }

        .games-container {
            margin-top: 40px;

            .game-link {
                width: 100%;
                height: 150px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 10px;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    pointer-events: none;
                    background: black;
                    opacity: .3;
                    transition: opacity .3s ease-out;
                }
    
                &.more-ore {
                    background: url('./../images/moreOre.png');
                    background-position: 5% 5%;
                    background-size: 100% auto;
                }

                .game-name {
                    color: white;
                    font-family: 'Oswald';
                    font-weight: 400;
                    position: relative;
                    opacity: .3;
                    transition: opacity .3s ease-out;
                }

                p {
                    position: relative;
                    color: #ccc;
                    max-width: 400px;
                    text-align: center;
                    height: 0;
                    overflow: hidden;
                    opacity: 0;
                    transition: all .3s ease-out;
                }

                &:hover {
                    &::before {
                        opacity: .6;
                    }
                    .game-name {
                        opacity: 1;
                    }
                    p {
                        opacity: 1;
                        height: 38px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    
    .page-container {
        display: block;
        
        .content-container {
            margin: 0 auto;
            padding-top: 20px;
            width: calc(100% - 40px);

            header {
                flex-flow: column nowrap;
                
                .frame {
                    margin-bottom: 40px;
                }

                .header-text-container {
                    margin-left: 0;
                    padding: 20px 25px;

                    h1 {
                        font-size: 35px;
                        line-height: 40px;
                        margin-bottom: 10px;
                    }
                }
            }

            .games-container .game-link.more-ore {
                height: 200px;
                background-size: cover;
                // background-position: 50% 50%;

                p, .game-name {
                    padding: 0 10px;
                }
            }
        }
    }
}